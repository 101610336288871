<template>
  <div class="consortium">
    <div class="top_Img">
      <img src="../../assets/banner/contact.jpg" alt="">
      <div class="tips">联系我们</div>
    </div>
    <div class="details">
      <p>随着新一轮科技革命与产业变革，智能制造作为产业变革的重要方向，正成为全球制造业变革和科技创新的制高点。为加快推动新一代信息技术与制造技术融合发展，适应学科广泛交叉、领域深度融合的发展趋势，在中国科协的积极倡导和指导下，由中国机械工程学会、中国仪器仪表学会、中国汽车工程学会、中国电工技术学会、中国电子学会、中国自动化学会、中国农业机械学会、中国人工智能学会、中国微米纳米技术学会、中国光学工程学会、中国纺织工程学会、中国宇航学会和中国造船工程学会等13家与智能制造有关的中国科协所属全国学会广泛联合相关企业、科研机构、高等院校，组建成立了“中国科协智能制造学会联合体”（以下简称“联合体”）。目前，联合体成员由13家全国学会、16家企业、12家科研机构和12家高等院校共53家单位组成。</p>
      <p>联合体是非独立法人联合组织，由主席团、专家委员会和秘书处组成。主席团是联合体的决策领导机构，领导联合体开展工作，决策重大事务，统一协调运作。设主席、副主席、主席团成员单位。专家委员会接受主席团领导，负责对联合体的发展和主体业务提出咨询和指导。目前，专家委员会由85位专家组成，其中院士39位。秘书处是联合体的常设办事机构，受联合体主席团领导，负责组织联合体各项具体工作的实施。秘书处固定办公场所，设在中国机械工程学会。联合体主席和秘书长实行任期制，每届任期为3年。</p>
      <p>联合体将致力于打造智能制造领域高端智库、搭建智能制造领域的高水平学术交流平台、协同创新和科技成果转移转化平台、人才培养平台（简称“一智库三平台”），为促进学会、企业、科研机构和高等院校在战略层面有效结合，突破相关产业发展的技术瓶颈和体制约束、增强我国智能制造技术创新能力、促进我国制造业向中高端迈进做贡献。</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  methods:{
    // 查看详情
    handleDetails(){
      this.$router.push({path:'messageDetails',query:{isTop:'Top'}});
    }
  },
  mounted(){

  }
}
</script>

<style lang="scss" scoped>
  .consortium{
    .top_Img{position: relative;
      img{width: 100%;max-width: 100%;height: 100%;}
      .tips{position: absolute;top:50%;left:15%;font-size: 40px;font-weight: bold;color: #fff;}
    }
    .details{max-width: 960px;margin: 0 auto;margin-top: 30px;padding-top:0;
      p{text-indent: 28px;}
    }
  }
</style>